@import "./App-variables.scss";
@import "~antd/dist/antd.css";

/* TODO: Clean up extra stuff */

body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: $font-montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  overflow-x: hidden !important;
}

.company-container {
  max-width: 1100px;
  min-width: 0px;
  margin: 0px auto;
}

.company-name {
  color: white; 
  font-size: 64px;
  font-weight: 700;
  margin-top: 40px;
}

.company-tagline {
  color: white;
  font-size: 24px;
  font-weight: 700;
  font-family: $font-lato;
  margin-top: 10px;
  margin-bottom: 40px; 
  text-align: center;
}


@media (max-width: 576px) {
  .company-container {
    margin-left: 15px;
    margin-right: 15px;
  }

  .company-name {
    font-size: 48px;
  }
  .company-tagline {
    font-size: 20px;
  }
}

.divider-custom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider-custom .divider-custom-line {
  width: 100%;
  max-width: 7rem;
  height: 0.25rem;
  background-color: #2c3e50;
  border-radius: 1rem;
  border-color: #2c3e50 !important;
}

.divider-custom .divider-custom-line:first-child {
  margin-right: 1rem;
}

.divider-custom .divider-custom-line:last-child {
  margin-left: 1rem;
}

.divider-custom .divider-custom-icon {
  color: #2c3e50 !important;
  font-size: 2rem;
}

.divider-custom.divider-light .divider-custom-line {
  background-color: #fff;
}

.divider-custom.divider-light .divider-custom-icon {
  color: #fff !important;
}

.email-subscribe-header {
  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
}
.email-subscribe-input {
  border-radius: 0.5rem;
  border: 0.125rem solid #ced4da;
  height: 2.5rem;
  font-family: $font-lato;
  font-size: 18px;
  width: 400px;
}
.email-subscribe-footer {
  margin-top: 10px;
  margin-bottom: 100px !important;
}

@media (max-width: 576px) {
  .email-subscribe-input {
    width: 100%;
  }
}

.email-subscribe-input:hover {
  border-color: $highlighted-color;
}
.email-subscribe-input:focus {
  border-color: $highlighted-color;
}
.email-subscribe-button {
  border-radius: 0.5rem;
  height: 2.5rem;
  font-family: $font-lato;
  font-size: 18px;
  font-weight: 500;
}
.email-subscribe-button:hover {
  border-color: $highlighted-color;
}


.main-results {
  background-color: white;
  border-color: lightgray;
  border-radius: 26px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding-bottom: 20px;
  overscroll-behavior: none;
  padding: 20px 50px;
}

@media (max-device-width: 576px) {
  .main-results {
    padding: 5px 10px !important;
  }
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
}

@media (max-device-width: 576px) {
  h1 {
    font-size: 28px !important;
  }
  .call-to-action-outer {
    margin-left: -4px !important;
    margin-top: 6px;
    text-align: left !important;
  }
  .place-address {
    font-size: 0.9em;
  }
}

.app {
  text-align: center;
  overscroll-behavior: none;
}


.flex-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app-link {
  color: $tertiary-text;
}


.hero-row {
  height: 450px;
  background-color: $primary-color;
  padding-top: 20px;
  overscroll-behavior: none;
}

.call-to-action-outer {
  text-align: right;
  margin-left: 20px;
}

.primary-button {
  background-color: $primary-color !important;
  color: white !important;
}
.primary-button:hover,
.primary-button:active {
  background-color: $primary-color !important;
  color: white !important;
}

.large-primary-button {
  border: 0;
  background-color: $primary-color !important;
  color: white !important;
}
.header-button {
  color: $primary-color;
  background-color: white;
  position: relative;
  bottom: 2px;
}

@media only screen and (max-width: 700px) {
  .header-share-link {
    display: none !important;
  }
}

.header-link {
  color: white;
  display: inline;
  margin-right: 20px;
}

.secondary-button {
  border: 0;
  color: $tertiary-text !important;
  background-color: $tertiary-color !important;
}

.secondary-button:hover {
  color: $primary-color;
}

h2.place-title,
h3.place-title,
h4.place-title {
  color: $tertiary-text !important;
  margin-bottom: 0;
}

.primary-link {
  color: $primary-color;
}
.primary-link:hover {
  color: $primary-color;
  text-decoration: underline;
}

.place-address {
  color: darkgray;
}

.action-button {
  font-family: $font-lato;
  font-size: 16px;
}


h4.suggestion-title {
  color: $text-color;
  margin-bottom: 0;
  font-size: 1.1em;
}

@media (max-width: 700px) {
  .action-button {
    font-size: 12px;
  }
  h4.suggestion-title {
    font-size: 12px !important;
  }
}


h4.section-title {
  font-size: 1.1em;
  font-weight: 400;
  padding-top: 0px;
  color: $text-color;
}


h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography {
  color: $text-color;
}

.top-header {
  padding: 0px 12px;
}

.top-header h1,
.top-header h2,
.top-header h3,
.top-header h4 {
  color: white !important;
  font-weight: 400;
}



.autosuggest-outer {
  max-width: 500px;
  margin: 0 auto;
}

.suggestions-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: $max-content-width;
  margin: 24px auto 24px auto;
}


.suggested-place {
  outline: 1px solid lightgray;
  min-width: 150px;
  width: 30%;
  margin: 10px 10px 10px 10px; // top, right, bottom, left
}

@media (max-width: 576px) {
  .suggested-place {
    margin: 5px 5px 5px 5px; // top, right, bottom, left
  }

}

.suggested-place span.photo-attribution {
  display: none;
}

.suggested-place:hover span.photo-attribution {
  display: inline;
  background-color: white;
}

.suggested-place:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.suggested-place h4 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 400;
}

.suggested-place .suggestion-details {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  color: darkgray;
  font-weight: 600;
  font-size: 0.8em;
  font-variant: small-caps;
}

