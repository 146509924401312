$primary-color: #1abc9c;
$highlighted-color: #6bebd1;
$secondary-color: #caeae7;
$tertiary-color: #e7f5f4;
$tertiary-text: #00796f;

$font-montserrat: Montserrat,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$font-lato: Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

$max-content-width: 850px;
$search-text-color: #626262;
$text-color: #414141;
